import {
  currencyWithDecimals,
  formatAprValue,
  formatNumberWithCommas,
  safeSum,
} from "utils/shared";
import { getCompensationValues } from "utils/compensationFields";
import moment from "moment";
import { useLoanInformationField } from "data/useSelectors";
import { isLOComp } from "view/SearchResult/helpers";
import Rights from "common/services/Rights";

export const getFICO = (borrowers) => {
  if (borrowers && borrowers.length) {
    return borrowers[0].creditScore;
  }
  return "";
};

export const getCalculatedValues = (LTV, CLTV, HCLTV) => {
  const getPercentValue = (value) => {
    if (typeof value !== "number") return "-";
    if (value === 0) return "0";
    return (value * 100).toFixed(3);
  };

  const ltvValue = `${getPercentValue(LTV)}%`;
  const cltvValue = `${getPercentValue(CLTV)}%`;
  const hcltValue = `${getPercentValue(HCLTV)}%`;
  return `${ltvValue} / ${cltvValue} / ${hcltValue}`;
};

export const formatIndexPercentage = (index) => {
  return `${index ? index.toFixed(3) : ""}`;
};

export const setLoanInformation = (lien, loan) => {
  const {
    apr,
    baseLoanAmount,
    borrowers,
    compensationModel,
    compensationPoints,
    compensationDollars,
    amount,
    bRatio,
    property,
    feeAmountFinanced,
  } = loan;

  const {
    programTypeName,
    rate,
    price,
    commission,
    commissionDollar,
    rateDate,
    ltv,
    cltv,
    hcltv,
    displayedLockDays,
  } = lien;

  let compensationText = "";
  if (compensationModel) {
    compensationText =
      compensationModel === 1 ? "Borrower-Paid" : "Lender-Paid";
  }

  const { financedAmount, loanPurpose } = useLoanInformationField([
    "financedAmount",
    "loanPurpose",
  ]);
  const parsedFinancedAmount = parseInt(financedAmount, 10);
  const parsedFeeFinancedAmount = parseFloat(feeAmountFinanced) || 0;
  const totalLoanAmount = safeSum(
    amount,
    parsedFinancedAmount,
    parsedFeeFinancedAmount
  );

  const propertyValueLabel =
    loanPurpose === 1 ? "Purchase Price/Property Value" : "Property Value";

  const [compPoints, compValue] = getCompensationValues(
    compensationModel,
    compensationPoints,
    compensationDollars
  );

  const showComp = isLOComp();

  const loanInformation = [
    {
      label: "Base Loan Amount",
      value: `$${formatNumberWithCommas(baseLoanAmount)}`,
      key: "baseLoanAmount",
    },
    {
      label: "Total Loan Amount",
      value: `$${formatNumberWithCommas(totalLoanAmount)}`,
      key: "totalLoanAmount",
    },
    {
      label: propertyValueLabel,
      value: `$${formatNumberWithCommas(property?.value || null)}`,
      key: "propertyValue",
    },
    {
      label: "LTV/CLTV/HCLTV",
      value: `${getCalculatedValues(ltv, cltv, hcltv)}`,
      key: "loanToValue",
    },
    {
      label: "Lock Period",
      value: displayedLockDays ? `${displayedLockDays} Days` : "",
      key: "lockPeriod",
    },
    {
      label: "Term",
      value: programTypeName,
      key: "term",
    },
    {
      label: "Rate",
      value: rate ? `${rate.toFixed(3)}%` : "",
      key: "rate",
    },
    {
      label: "DTI",
      value: bRatio ? (bRatio * 100).toFixed(1) : "0.0",
    },
    ...(Rights.viewAPR
      ? [
          {
            label: "Estimated APR",
            value: formatAprValue(apr),
            key: "estimatedApr",
            supTag: "<sup>1<sup>",
          },
        ]
      : []),
    {
      label: "Price",
      value: price ? price.toFixed(3) : "",
      key: "price",
    },
    {
      label: "Discount/Rebate Points",
      value: commission ? commission.toFixed(3) : "",
      key: "discountRebatePoints",
    },
    {
      label: "Discount/Rebate $",
      value: currencyWithDecimals(Math.round(commissionDollar)),
      key: "discountRebateDollar",
    },
    {
      label: "Rate Effective Date",
      value: `${moment(rateDate).format("MM/DD/YYYY hh:mm A")} ET`,
      key: "rateEffeectiveDate",
    },
    {
      label: "Representative Credit Score",
      value: getFICO(borrowers),
      key: "representativeCreditScore",
    },
    {
      label: "Avg. Repr Credit Score",
      value: loan.creditAvg,
      key: "averageRepresentativeCreditScore",
    },
    {
      label: "Compensation",
      value: compensationText,
      key: "compensation",
    },
    ...(showComp
      ? [
          {
            label: "Compensation %",
            value: compPoints,
            key: "compensationPercent",
          },
          {
            label: "Compensation $",
            value: compValue,
            key: "compensationDollar",
          },
        ]
      : []),
  ];

  return loanInformation;
};

export const setConditionalLoanInformation = (lien) => {
  const { armIndex, armIndexValueDate, armIndexValue } = lien;
  return [
    {
      label: "Index",
      key: "index",
      value:
        armIndexValue && armIndexValueDate
          ? `${formatIndexPercentage(armIndexValue)}% as of ${moment(
              armIndexValueDate
            ).format("MM/DD")}`
          : "",
    },
    {
      label: "Index Based on",
      value: armIndex,
      key: "indexBasedOn",
    },
  ];
};
