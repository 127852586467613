import React, { useState } from "react";
import { bool, func, number, oneOfType, shape, string } from "prop-types";
import { Grid } from "@elliemae/ds-grid";
import { DSButtonV2 } from "@elliemae/ds-button";
import { DSModal, MODAL_TYPE_V2, MODAL_SUB_TYPE_V2 } from "@elliemae/ds-modal";
import {
  DSInputMask,
  DSCheckbox,
  DSTextBox,
  MASK_TYPES,
} from "@elliemae/ds-form";
import { Delete } from "@elliemae/ds-icons";
import { DSSeparator } from "@elliemae/ds-separator";
import { DSFormLayoutBlockItem } from "@elliemae/ds-form-layout-blocks";
import { parseDecimals } from "./helpers";
import { StyledCard } from "./styled";

const AdjustmentCard = ({ index, rowCard, onChange, onDeleteRow, errors }) => {
  const [openModal, setOpenModal] = useState(false);
  const isCustomPriceAdjustment =
    rowCard.adjustmentType === "CustomPriceAdjustment";
  return (
    <>
      <StyledCard>
        <Grid
          cols={["45%", "26%", "26%", "0%", "3%"]}
          gutter={5}
          alignItems="flex-start"
        >
          <DSFormLayoutBlockItem
            label="Adjustment Name"
            inputID="nameAdjustment"
            required
            hasError={![undefined, null, ""].includes(errors?.description)}
            validationMessage={errors?.description}
            withHighlight={Boolean(errors?.description)}
          >
            <DSTextBox
              value={rowCard.description}
              labelText="Adjustment Name"
              onChange={({ target: { value } }) =>
                onChange(value, "description", index)
              }
              maxLength={256}
              readOnly={rowCard.status === "Submitted"}
            />
          </DSFormLayoutBlockItem>
          {isCustomPriceAdjustment && (
            <DSFormLayoutBlockItem
              label="Rate"
              inputID="rateAdjustment"
              hasError={![undefined, null, ""].includes(errors?.rate)}
              validationMessage={errors?.rate}
              withHighlight={Boolean(errors?.rate)}
            >
              <DSInputMask
                mask={(num) =>
                  MASK_TYPES.NUMBER({
                    prefix: "",
                    integerLimit: 2,
                    allowDecimal: true,
                    allowNegative: true,
                    decimalLimit: 3,
                  })(num)
                }
                name="rate"
                value={rowCard.rate}
                placeholder=""
                labelText="Rate"
                onChange={({ target: { value } }) => {
                  onChange(value, "rate", index);
                }}
                onBlur={({ target: { value } }) => {
                  onChange(parseDecimals(value, 3), "rate", index);
                }}
                readOnly={rowCard.status === "Submitted"}
              />
            </DSFormLayoutBlockItem>
          )}
          <DSFormLayoutBlockItem
            label="Price"
            inputID="priceAdjustment"
            hasError={![undefined, null, ""].includes(errors?.price)}
            validationMessage={errors?.price}
            withHighlight={Boolean(errors?.price)}
            required={Boolean(!isCustomPriceAdjustment)}
          >
            <DSInputMask
              mask={(num) =>
                MASK_TYPES.NUMBER({
                  prefix: "",
                  integerLimit: isCustomPriceAdjustment ? 2 : 1,
                  allowDecimal: true,
                  allowNegative: true,
                  decimalLimit: 3,
                })(num)
              }
              name="price"
              value={rowCard.price}
              placeholder=""
              labelText="Price"
              onChange={({ target: { value } }) => {
                onChange(value, "price", index);
              }}
              onBlur={({ target: { value } }) => {
                onChange(parseDecimals(value, 3), "price", index);
              }}
              readOnly={rowCard.status === "Submitted"}
            />
          </DSFormLayoutBlockItem>
          <DSCheckbox
            labelText="Persist during price updates"
            checked
            name="persistPrice"
            id="persistPrice"
            containerProps={{
              style: { visibility: "hidden" },
            }}
          />
          <div style={{ textAlign: "right" }}>
            <DSButtonV2
              buttonType="icon"
              onClick={() => setOpenModal(true)}
              size="l"
              aria-label="Delete rowCard"
            >
              <Delete size="m" color={["brand-primary", 600]} />
            </DSButtonV2>
          </div>
        </Grid>
        <DSSeparator dashed style={{ marginBottom: `0`, marginTop: `0` }} />
      </StyledCard>
      <DSModal
        centered
        confirmLabel="Ok"
        isOpen={openModal}
        modalSubType={MODAL_SUB_TYPE_V2.WARNING}
        modalType={MODAL_TYPE_V2.DECISION}
        onReject={() => setOpenModal(false)}
        onClose={() => setOpenModal(false)}
        onConfirm={() => {
          onDeleteRow(index);
          setOpenModal(false);
        }}
        rejectLabel="Cancel"
        showClose
        showRejectButton
        version={2}
      >
        <p>Do you want to delete this entry?</p>
      </DSModal>
    </>
  );
};

AdjustmentCard.defaultProps = {
  index: 0,
  rowCard: {
    price: "",
    name: "",
    persist: true,
    persistDisabled: false,
    adjustmentType: "",
  },
  errors: {
    hasError: false,
    messages: [""],
  },
};

AdjustmentCard.propTypes = {
  onChange: func.isRequired,
  onDeleteRow: func.isRequired,
  index: number,
  rowCard: shape({
    name: string,
    price: oneOfType([number, string]),
    persist: bool,
    persistDisabled: bool,
    adjustmentType: string,
  }),
  errors: shape({
    hasError: false,
    messages: [],
  }),
};

export default AdjustmentCard;
