import React, { useState, useRef, useEffect } from "react";
import LockUpdateBox from "../LockUpdateBox";

const getLockStatusText = (currentIndex, relockEventDateList) => {
  let status = "";
  if (relockEventDateList[currentIndex].requestedStatus === "") {
    status = "Current Price";
  } else {
    status = relockEventDateList[currentIndex].requestedStatus;
  }
  return status;
};

const getWorseCaseIndex = (relockList) => {
  let worsePriceIndex = 0;
  const indexList = [];
  if (relockList) {
    const minPriceRelock = relockList.reduce(
      (min, p) => (p.price > min ? p.price : min),
      relockList[0].price
    );
    relockList.forEach((x, index) => {
      if (x.price === minPriceRelock) indexList.push(index);
    });

    worsePriceIndex =
      indexList.length === 1
        ? indexList[0] + 1
        : indexList[indexList.length - 1] + 1;
  }
  if (indexList.length === relockList.length) {
    return false;
  }
  return worsePriceIndex;
};

const populateRelockEventBox = (
  relockEventData,
  relockEventDateList,
  defaultRate,
  selectedLockBox
) => {
  let currSelBoxDefault = {};
  const defaultSelectFlag = useRef(0);
  const [selectLoan, setSelectLoan] = useState();
  const [currRate, setCurrRate] = useState("");
  const [currSelBox, setCurrSelBox] = useState(null);

  useEffect(() => {
    if (relockEventDateList && relockEventDateList.length > 0) {
      setSelectLoan(`${relockEventDateList[0].requestedDate}_1`);
    }
  }, [defaultRate]);

  const lockUpdateBoxList = [];
  const rateAvailableList = [];

  let relockBoxCount = 0;
  if (relockEventDateList && relockEventData) {
    let rateAvailableCount = 1;
    let worseCaseIndex = 1;
    Object.keys(relockEventData)
      .reverse()
      .forEach((x) => {
        if (relockEventData[x] !== null) {
          rateAvailableList.push(relockEventData[x][0]);
        }
        relockBoxCount += 1;
      });
    if (currRate !== defaultRate) {
      defaultSelectFlag.current = 0;
    }

    relockEventDateList.forEach((dateObj, index) => {
      const item = relockEventData[dateObj.requestedDate];
      if (item != null) {
        item.forEach((lockItem, num) => {
          const eventDateTime = dateObj.requestedDate;
          const uniqueKey = `${eventDateTime}_${num + 1}`;
          if (index === 0) {
            currSelBoxDefault = {
              specificRateDataID: lockItem.rateDataID,
              effectiveDate: dateObj.requestedDate,
              specificProgramID: lockItem.programID,
            };
          }
          const cardItem = (
            <LockUpdateBox
              lockDate={index === 0 ? "TODAY" : dateObj.date}
              defaultDateTime={dateObj.time}
              effectiveDate={eventDateTime}
              lockStatus={getLockStatusText(
                rateAvailableCount - 1,
                relockEventDateList
              )}
              uniqId={uniqueKey}
              isduplicateFlag={dateObj.duplicate}
              loanEventData={lockItem}
              lockIcon={index === 1 && true}
              worseIcon={
                getWorseCaseIndex(rateAvailableList) === worseCaseIndex &&
                true &&
                relockEventDateList.length > 1
              }
              selectedEventBox={
                defaultSelectFlag.current === 0 ? uniqueKey : selectLoan
              }
              handleLoanSelect={(val) => {
                setSelectLoan(val.uId);
                setCurrRate(defaultRate);
                setCurrSelBox({
                  effectiveDate: val.effectiveDate,
                  specificRateDataID: val.rateDataID,
                  specificProgramID: val.programID,
                });
              }}
            />
          );
          lockUpdateBoxList.push(cardItem);
          defaultSelectFlag.current += 1;
          worseCaseIndex += 1;
        });
      } else {
        const cardItem = (
          <LockUpdateBox
            lockDate={index === 0 ? "TODAY" : dateObj.date}
            defaultDateTime={dateObj.time}
            lockIcon={index === 1 && false}
            lockStatus={getLockStatusText(
              relockBoxCount,
              rateAvailableCount - 1
            )}
            isduplicateFlag={dateObj.duplicate}
            loanEventData={{
              rateNotAvailable: true,
              rate: null,
              basePrice: null,
              priceVal: null,
              price: null,
            }}
          />
        );
        lockUpdateBoxList.push(cardItem);
      }
      rateAvailableCount += 1;
    });
  }
  selectedLockBox(currSelBox || currSelBoxDefault);
  return lockUpdateBoxList;
};

export default populateRelockEventBox;
