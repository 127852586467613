import { optionsLOmode, optionsSecondaryMode } from "./options";

export const getMenuOptions = (
  tabId,
  isSecondary,
  disablePricingPage,
  disableRevised
) => {
  return !isSecondary
    ? optionsLOmode(tabId)
    : optionsSecondaryMode(disablePricingPage, disableRevised);
};

export const checkIsLockRevise = (lockid, lockRequests) => {
  if (!lockid || !Array.isArray(lockRequests) || lockRequests.length === 0)
    return false;
  const filteredRequest = lockRequests.filter(
    (lockRequest) => lockRequest.id === lockid
  );
  if (!filteredRequest || filteredRequest.length === 0) return false;
  return filteredRequest[0]?.requestedStatus === "Requested";
};
