export const runFetch = async (fetchFn) => {
  try {
    const response = await fetchFn();
    return response;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log("runFetch.error, ", err);
    return Promise.reject(err);
  }
};

export const runFetchJson = async (fetchFn) => {
  try {
    const response = await runFetch(fetchFn);
    if (response && response.status === 201) return await response;
    return await response.json();
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log("runFetchJson.error, ", err);
    return Promise.reject(err);
  }
};
